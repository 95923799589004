export const PreLoader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      </div>
    </div>
  );
};
