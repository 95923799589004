import jwtDecode from "jwt-decode";
import { newGuid } from "./apiClient";
import { useLocation, useNavigate } from "react-router-dom";

/**
 * Checks if user is authenticated
 */

const isUserAuthenticated = () => {
  return JSON.parse(localStorage.getItem("userInfo"))?.tokenId ? true : false;
};

/**
 * Sets the logged in user
 */
const setLoggedInUser = (user) => {
  localStorage.setItem("authUser", JSON.stringify(user));
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser");
  return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};
const getUserInfo = () => {
 
  return JSON.parse(localStorage.getItem("userInfo"));
};
// return user info

export { isUserAuthenticated, setLoggedInUser, getLoggedInUser, getUserInfo };
