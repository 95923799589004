import config from "./../config";

export const getNatureAllOpportunity = async (natureId) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return fetch(
    `${
      process.env.REACT_APP_BASE_URL_ONE
    }/nature-of-business/${natureId}/opportunities?is_complete=true&auth_token=${
      userInfo.tokenId
    }&page_size=500&ln=${localStorage.getItem("i18nextLng")}`
  ).then((res) => res.json());
};
export const getAllIndustries = async () => {
  return fetch(`${process.env.REACT_APP_BASE_URL_ONE}/industries`).then((res) =>
    res.json()
  );
};
export const telegramDownload = async (opportunityId, currentUrl) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const name = userInfo.name;
  console.log("telegramDownload", opportunityId, currentUrl, userInfo);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: userInfo.tokenId,
    },

    body: JSON.stringify({ opportunityId, currentUrl, name }),
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL_TWO}/C17D2233`,
      requestOptions
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error; // You might want to handle errors differently
  }
};
export const sendInfo = async (opportunityId) => {
  console.log("sendInfo");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const url = `${process.env.REACT_APP_BASE_URL_TWO}/25799E3F`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: userInfo.tokenId,
    },
    body: JSON.stringify({ oppId: opportunityId }),
  };

  return fetch(url, requestOptions)
    .then((res) => res.json())
    .catch((error) => console.error("Error:", error));
};

export const getNatureOfBusiness = async () => {
  return fetch(
    `${
      process.env.REACT_APP_BASE_URL_ONE
    }/nature-of-business?is_active=true&ln=${localStorage.getItem(
      "i18nextLng"
    )}`
  ).then((res) => res.json());
};
export const getOpportunity = async (natureId, opportunityId, auth_token) => {
  console.log("getOpportunity", opportunityId, auth_token);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = auth_token || userInfo.tokenId;
  return fetch(
    `${process.env.REACT_APP_BASE_URL_ONE}/nature-of-business/${
      userInfo.natureOfBussinessId
    }/opportunities/${opportunityId}?auth_token=${token}&target_lan_code=${localStorage.getItem(
      "i18nextLng"
    )}`
  ).then((res) => res.json());
};

export const getOrgDetail = async (orgId) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return fetch(
    `${process.env.REACT_APP_BASE_URL_ONE}/organizations/${orgId}?auth_token=${
      userInfo.tokenId
    }&target_lan_code=${localStorage.getItem("i18nextLng")}`
  ).then((res) => res.json());
};

export const getPersons = async (orgId) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return fetch(
    `${process.env.REACT_APP_BASE_URL_ONE}/organizations/${orgId}/persons?auth_token=${userInfo.tokenId}`
  )
    .then((res) => res.json())
    .catch((err) => err);
};
export const getSinglePerson = async (orgId, personId) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return fetch(
    `${process.env.REACT_APP_BASE_URL_ONE}/organizations/${orgId}/persons/${personId}?auth_token=${userInfo.tokenId}`
  ).then((res) => res.json());
};
export const getFundingNews = async (fundingId) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return fetch(
    `${process.env.REACT_APP_BASE_URL_ONE}/news/funding/${fundingId}?auth_token=${userInfo.tokenId}`
  ).then((res) => res.json());
};
export const lockedAndUnlockedDetails = async () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const organisationId = userInfo.orgId;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: userInfo.tokenId,
    },
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL_TWO}/09CBC6A0?MIorgId=${organisationId}`,
    requestOptions
  ).then((response) => response.json());
};
export const updateCredits = async ({
  orgId,
  contactId,
  reveal2,
  reveal3,
  opportunityId,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const organisationId = userInfo.orgId;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: userInfo.tokenId,
    },
  };
  const oppIdFromLocalStorage = localStorage.getItem("oppId");

  // Use the value from localStorage if present, otherwise use the provided opportunityId
  const opportunityId1 = oppIdFromLocalStorage || opportunityId;

  return fetch(
    `${process.env.REACT_APP_BASE_URL_TWO}/250274A4?reveal2Done=${
      reveal2 || true
    }&organisationId=${
      orgId || null
    }&oppId=${opportunityId1}&MIorgId=${organisationId}`,
    requestOptions
  ).then((response) => response.json());
};
export const updateCredits2 = async ({
  orgId,
  contactId,
  reveal2,
  reveal3,
  opportunityId,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const organisationId = userInfo.orgId;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: userInfo.tokenId,
    },
  };
  const oppIdFromLocalStorage = localStorage.getItem("oppId");

  // Use the value from localStorage if present, otherwise use the provided opportunityId
  const opportunityId1 = oppIdFromLocalStorage || opportunityId;

  return fetch(
    `${process.env.REACT_APP_BASE_URL_TWO}/3F5840A2?OpportunityId=${opportunityId1}&MIorgId=${organisationId}`,
    requestOptions
  ).then((response) => response.json());
};

export const getRevealInformation = async ({ oppId, orgId }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: userInfo.tokenId,
    },
  };
  return fetch(
    `${process.env.REACT_APP_BASE_URL_TWO}/07B0DD5B?oppId=${oppId}&orgId=${orgId}&MIorgId=${userInfo.orgId}`,
    requestOptions
  ).then((response) => response.json());
};
// What kind of consulting do you offering [check boxes]
//    [Recruitment Process Outsourcing
//    Staffing Agency recruiter
//    Contingency Search firm
//    Retained Recruitment firm]

//   What kind of models you recruit for [check boxes]
//    [Office model
//    Office-first (remote-friendly) hybrid model
//    Partly remote hybrid model
//    Flexible hybrid model
//    Choose-your-own-adventure hybrid model
//    Remote-first hybrid model
//    Fully remote model

//   What kind of typical roles you have recruited
