import React from "react";
import { Navigate } from "react-router-dom";
import Verify from "../pages/StarterPage/verify";
import Landing from "../pages/Landing";
import DesignPartner from "../pages/DesignPartner";
import Invite from "../pages/invite";

// lazy load all the views
const Dashboard = React.lazy(() => import("../pages/dashboard/index"));
const StarterPage = React.lazy(() => import("../pages/StarterPage/index"));
const Credit = React.lazy(() => import("../pages/Credit"));
// auth
const Login = React.lazy(() => import("../pages/Auth/Login"));
const Logout = React.lazy(() => import("../pages/Auth/Logout"));
const PageNotFound = React.lazy(() => import("../pages/404/PageNotFound"));
const Sample = React.lazy(() => import("../pages/sample/index"));
const ForgetPassword = React.lazy(() => import("../pages/Auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/Auth/Register"));
const LockScreen = React.lazy(() => import("../pages/Auth/LockScreen"));
const Onboarding = React.lazy(() => import("../pages/Onboard"));
const opportunityPath = "/:natureId";

// declare all routes
const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/:natureId", component: <Dashboard /> },

  // { path: "/pages-starter", component: <StarterPage /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
];

const publicRoutes = [
  // { path: "/", component: <Landing /> },
  // { path: "/design-partner", component: <DesignPartner /> },
  // { path: "/invite/:id", component: <Invite /> },
  { path: "/verify", component: <Verify /> },
  { path: "/login", component: <Login /> },
  { path: "/", component: <Login /> },
  { path: "/credit", component: <Credit /> },
  { path: "/Onboarding", component: <Onboarding /> },
  { path: "*", component: <PageNotFound /> },
  { path: "/sample/:natureId/:natureId", component: <Sample /> },
];

export { authProtectedRoutes, publicRoutes };

// /114e2abd-f1a4-48b0-9ccf-87970428dc25?opportunity=371cd000-52a4-4797-a150-51f9e94ab308
