import { getRevealInformation } from "./services/opportunity";

const config = {
  API_URL: "https://moukha.backend.get1page.net/api/v3",
  GETREVEALINFORMATION: "07B0DD5B",
  google:
    "1083372080504-hmc46v20c1076vp9rsp2rob6kod37egc.apps.googleusercontent.com",
  API_URL_TRACK:
    "https://cros-anywhere-proxy.onrender.com/https://ap-south-1.aws.data.mongodb-api.com/app/data-mjnxo/endpoint/data/v1/action/insertOne",
  API_KEY: "61nqacn6skr0ncYZMGlaRBEjkYKMU9KpYfD7eEPgnNm4qq666uX5T1x14xxlcNug",
  BASIC: "bIY8wFedieMPakg6oH",
  STANDARD: "5kAdQZ8SY8or8c85kE",
  PREMIUM: "7sI5ktd9e9sv9gc14p",
  PREMIUMPLUS: "28ofZ7fhmbAD1NK9AW",
};

export default config;
