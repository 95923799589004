import {
  CHAT_USER,
  ACTIVE_USER,
  FULL_USER,
  ADD_LOGGED_USER,
  CREATE_GROUP,
  TOGGLE_LOCK,
  TOGGLE_OPPORTUNITY_LOCK,
  ADD_OPPORTUNITY_DETAILS,
  ADD_OPPORTUNITIES,
  UPDATE_REVEAL,
  OPENED_REVEAL,
  GLOBAL_SEARCH,
} from "./constants";

//Import Images
import avatar2 from "../../assets/images/users/avatar-2.jpg";

import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import avatar7 from "../../assets/images/users/avatar-7.jpg";
import avatar8 from "../../assets/images/users/avatar-8.jpg";

import img4 from "../../assets/images/small/img-4.jpg";

import img7 from "../../assets/images/small/img-7.jpg";

const INIT_STATE = {
  isContentBlurred: false,
  active_user: 0,
  opportunities: [],
  users: [
    //admin is sender and user in receiver
    {
      id: 0,
      name: "May Mobility has secured Series D funding of $105 million to accelerate the advancement and commercialization of its AV technology and services in the United States, Canada, and Japan, as well as scale operations and reach profitability.",
      profilePicture: avatar2,
      status: "online",
      unRead: 0,
      blurred: false,
      roomType: "contact",
      isGroup: false,
      messages: [
        {
          id: 1,
          message: "company_name:",
          time: " May Mobility",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 2,
          message: "company_description:",
          time: "May Mobility develops and deploys autonomous vehicle (AV) technology to transform cities through autonomous vehicle technology, driving more accessible and sustainable transportation and safer roads, while filling gaps in existing transportation infrastructure.",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 3,
          message: "summary_of_this_opportunity: ",
          time: "May Mobility has secured Series D funding of $105 million to accelerate the advancement and commercialization of its AV technology and services in the United States, Canada, and Japan, as well as scale operations and reach profitability.",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 4,
          message: "reason_of_this_opportunity:",
          time: "The opportunity arises due to the funding secured by May Mobility, which allows them to further develop and expand their autonomous vehicle technology and services.",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },

        {
          id: 5,
          message: "how_this_is_an_opportunity_benefits_for_you: ",
          time: "This opportunity benefits our company as a HR consulting business by potentially collaborating with May Mobility to provide human resources consulting services as they scale their operations and hire more employees.",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 6,
          message: "is_this_company_have_an_intent_for_your_company",
          time: "false",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 7,
          message: "did_this_company_researched_about_your_competitors",
          time: "false",
          userType: "receiver",
          isImageMessage: true,
          isFileMessage: false,
          imageMessage: [{ image: img4 }, { image: img7 }],
        },
        {
          id: 8,
          message: "industry_and_space_of_funded_company",
          time: "",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 9,
          message: "products_of_funded_company:",
          time: "null",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 10,
          message: "services_of_funded_company:",
          time: "null",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 11,
          message: "who_should_you_consider_your_first_call_in_this_company: ",
          time: "Edwin Olson (CEO and co-founder)",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 12,
          message: "what_is_the_decision_making_hierarchy_in_this_company:",
          time: "Decision-making hierarchy details not found",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 13,
          message: "strategic_sound_bite_that_make_this_opportunity_success",
          time: "Collaborating with May Mobility allows us to tap into the growing autonomous vehicle industry and leverage their advanced technology to provide innovative HR consulting solutions.",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 14,
          message: "created_at",
          time: "2023-11-14T12:16:16.573Z",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 15,
          message: "",
          time: "",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
      ],
    },
    {
      id: 1,
      name: "Tabnine has secured Series B funding of $25 million.",
      profilePicture: avatar3,
      status: "away",
      blurred: true,
      isGroup: false,
      messages: [
        {
          id: 1,
          message: "About",
          time: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in ",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 2,
          message: "Why this opportunity?",
          time: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 3,
          message: "How is it an opportunity for you?",
          time: "and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 4,
          message: "Is there an intent for your website from them?",
          time: "and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        { id: 33, isToday: true },
        {
          id: 5,
          message: "Have they researched your competitors?",
          time: "and typesetting industry. Lorem Ipsum has been ",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 6,
          message: "What are the products the company offers?",
          time: "and typesetting industry. Lorem Ipsum has been the  type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 7,
          message: "What are the services the company offers?",
          time: "What are the products the company offers? hat are the products the company offers? hat are the products the company offers?",
          userType: "receiver",
          isImageMessage: true,
          isFileMessage: false,
          imageMessage: [{ image: img4 }, { image: img7 }],
        },
        {
          id: 8,
          message: "Who should you consider as your first call?",
          time: "hat are the products the company offers?hat are the products the company offers?hat are the products the company offers?hat are the products the company offers?hat are the products the company offers?hat are the products the company offers?",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 9,
          message: "What is in the decision-making hierarchy?",
          time: "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.                                                                       is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 10,
          message: "",
          time: "",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
      ],
    },
    {
      id: 3,
      name: "ViaNautis is seeking funding in the form of a Series A round, with a target of $25 million. The funds will be used for the advancement of their proprietary drug delivery platform, expansion of teams and facilities, and establishment of laboratory facilities.",
      profilePicture: avatar2,
      status: "online",
      unRead: 0,
      blurred: true,
      roomType: "contact",
      isGroup: false,
      messages: [
        {
          id: 1,
          message: "About the Company",
          time: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in ",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 2,
          message: "Why this opportunity?",
          time: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 3,
          message: "How is it an opportunity for you?",
          time: "and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 4,
          message: "Is there an intent for your website from them?",
          time: "and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        { id: 33, isToday: true },
        {
          id: 5,
          message: "Have they researched your competitors?",
          time: "and typesetting industry. Lorem Ipsum has been ",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 6,
          message: "What are the products the company offers?",
          time: "and typesetting industry. Lorem Ipsum has been the  type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 7,
          message: "What are the services the company offers?",
          time: "What are the products the company offers? hat are the products the company offers? hat are the products the company offers?",
          userType: "receiver",
          isImageMessage: true,
          isFileMessage: false,
          imageMessage: [{ image: img4 }, { image: img7 }],
        },
        {
          id: 8,
          message: "Who should you consider as your first call?",
          time: "hat are the products the company offers?hat are the products the company offers?hat are the products the company offers?hat are the products the company offers?hat are the products the company offers?hat are the products the company offers?",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 9,
          message: "What is in the decision-making hierarchy?",
          time: "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.                                                                       is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 10,
          message: "",
          time: "",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
      ],
    },

    {
      id: 4,
      name: "There is a funding opportunity with Perfios.",
      profilePicture: avatar2,
      status: "online",
      unRead: 0,
      blurred: true,
      roomType: "contact",
      isGroup: false,
      messages: [
        {
          id: 1,
          message: "About the Company",
          time: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in ",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 2,
          message: "Why this opportunity?",
          time: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 3,
          message: "How is it an opportunity for you?",
          time: "and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 4,
          message: "Is there an intent for your website from them?",
          time: "and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        { id: 33, isToday: true },
        {
          id: 5,
          message: "Have they researched your competitors?",
          time: "and typesetting industry. Lorem Ipsum has been ",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 6,
          message: "What are the products the company offers?",
          time: "and typesetting industry. Lorem Ipsum has been the  type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 7,
          message: "What are the services the company offers?",
          time: "What are the products the company offers? hat are the products the company offers? hat are the products the company offers?",
          userType: "receiver",
          isImageMessage: true,
          isFileMessage: false,
          imageMessage: [{ image: img4 }, { image: img7 }],
        },
        {
          id: 8,
          message: "Who should you consider as your first call?",
          time: "hat are the products the company offers?hat are the products the company offers?hat are the products the company offers?hat are the products the company offers?hat are the products the company offers?hat are the products the company offers?",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 9,
          message: "What is in the decision-making hierarchy?",
          time: "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.                                                                       is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 10,
          message: "",
          time: "",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
      ],
    },
  ],
  groups: [
    {
      gourpId: 1,
      name: "#General",
      profilePicture: "Null",
      isGroup: true,
      unRead: 0,
      desc: "General Group",
      members: [
        { userId: 1, name: "Sara Muller", profilePicture: "Null", role: null },
        {
          userId: 2,
          name: "Ossie Wilson",
          profilePicture: avatar8,
          role: "admin",
        },
        {
          userId: 3,
          name: "Jonathan Miller",
          profilePicture: "Null",
          role: null,
        },
        { userId: 4, name: "Paul Haynes", profilePicture: avatar7, role: null },
        { userId: 5, name: "Yana sha", profilePicture: avatar3, role: null },
        {
          userId: 6,
          name: "Steve Walker",
          profilePicture: avatar6,
          role: null,
        },
      ],
    },
    {
      gourpId: 2,
      name: "#Reporting",
      profilePicture: "Null",
      isGroup: true,
      unRead: 23,
      desc: "reporing Group here...",
      members: [
        { userId: 1, name: "Sara Muller", profilePicture: "Null", role: null },
        {
          userId: 2,
          name: "Ossie Wilson",
          profilePicture: avatar8,
          role: "admin",
        },
        {
          userId: 3,
          name: "Jonathan Miller",
          profilePicture: "Null",
          role: null,
        },
        { userId: 4, name: "Paul Haynes", profilePicture: avatar7, role: null },
        { userId: 5, name: "Yana sha", profilePicture: avatar3, role: null },
        {
          userId: 6,
          name: "Steve Walker",
          profilePicture: avatar6,
          role: null,
        },
      ],
    },
    {
      gourpId: 3,
      name: "#Designer",
      profilePicture: "Null",
      isGroup: true,
      unRead: 0,
      isNew: true,
      desc: "designers Group",
      members: [
        { userId: 1, name: "Sara Muller", profilePicture: "Null", role: null },
        {
          userId: 2,
          name: "Ossie Wilson",
          profilePicture: avatar8,
          role: "admin",
        },
        {
          userId: 3,
          name: "Jonathan Miller",
          profilePicture: "Null",
          role: null,
        },
        { userId: 4, name: "Paul Haynes", profilePicture: avatar7, role: null },
        { userId: 5, name: "Yana sha", profilePicture: avatar3, role: null },
        {
          userId: 6,
          name: "Steve Walker",
          profilePicture: avatar6,
          role: null,
        },
      ],
    },
    {
      gourpId: 4,
      name: "#Developers",
      profilePicture: "Null",
      isGroup: true,
      unRead: 0,
      desc: "developers Group",
      members: [
        { userId: 1, name: "Sara Muller", profilePicture: "Null", role: null },
        {
          userId: 2,
          name: "Ossie Wilson",
          profilePicture: avatar8,
          role: "admin",
        },
        {
          userId: 3,
          name: "Jonathan Miller",
          profilePicture: "Null",
          role: null,
        },
        { userId: 4, name: "Paul Haynes", profilePicture: avatar7, role: null },
        { userId: 5, name: "Yana sha", profilePicture: avatar3, role: null },
        {
          userId: 6,
          name: "Steve Walker",
          profilePicture: avatar6,
          role: null,
        },
      ],
    },
    {
      gourpId: 5,
      name: "#Project-aplha",
      profilePicture: "Null",
      isGroup: true,
      unRead: 0,
      isNew: true,
      desc: "project related Group",
      members: [
        { userId: 1, name: "Sara Muller", profilePicture: "Null", role: null },
        {
          userId: 2,
          name: "Ossie Wilson",
          profilePicture: avatar8,
          role: "admin",
        },
        {
          userId: 3,
          name: "Jonathan Miller",
          profilePicture: "Null",
          role: null,
        },
        { userId: 4, name: "Paul Haynes", profilePicture: avatar7, role: null },
        { userId: 5, name: "Yana sha", profilePicture: avatar3, role: null },
        {
          userId: 6,
          name: "Steve Walker",
          profilePicture: avatar6,
          role: null,
        },
      ],
    },
    {
      gourpId: 6,
      name: "#Snacks",
      profilePicture: "Null",
      isGroup: true,
      unRead: 0,
      desc: "snacks Group",
      members: [
        { userId: 1, name: "Sara Muller", profilePicture: "Null", role: null },
        {
          userId: 2,
          name: "Ossie Wilson",
          profilePicture: avatar8,
          role: "admin",
        },
        {
          userId: 3,
          name: "Jonathan Miller",
          profilePicture: "Null",
          role: null,
        },
        { userId: 4, name: "Paul Haynes", profilePicture: avatar7, role: null },
        { userId: 5, name: "Yana sha", profilePicture: avatar3, role: null },
        {
          userId: 6,
          name: "Steve Walker",
          profilePicture: avatar6,
          role: null,
        },
      ],
    },
  ],
  contacts: [
    { id: 1, name: "Albert Rodarte" },
    { id: 2, name: "Allison Etter" },
    { id: 3, name: "Craig Smiley" },
    { id: 4, name: "Daniel Clay" },
    { id: 5, name: "Doris Brown" },
    { id: 6, name: "Iris Wells" },
    { id: 7, name: "Juan Flakes" },
    { id: 8, name: "John Hall" },
    { id: 9, name: "Joy Southern" },
    { id: 10, name: "Mary Farmer" },
    { id: 11, name: "Mark Messer" },
    { id: 12, name: "Michael Hinton" },
    { id: 13, name: "Ossie Wilson" },
    { id: 14, name: "Phillis Griffin" },
    { id: 15, name: "Paul Haynes" },
    { id: 16, name: "Rocky Jackson" },
    { id: 17, name: "Sara Muller" },
    { id: 18, name: "Simon Velez" },
    { id: 19, name: "Steve Walker" },
    { id: 20, name: "Hanah Mile" },
  ],
  company: {},
  people: [],
  opportunityDetail: [],
  opportunityReveal: {
    oppId: "",
    orgId: "",
    people: [],
    reveal2Done: false,
  },
  openedList: [],
  searchText: "",
};

const Chat = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHAT_USER:
      return { ...state };

    case UPDATE_REVEAL:
      const { payload } = action;
      return {
        ...state,
        opportunityReveal: {
          ...state.opportunityReveal,
          oppId: payload.oppId,
          orgId: payload.orgId,
          people: payload.people,
          reveal2Done: payload.reveal2Done,
        },
      };
    case OPENED_REVEAL:
      return {
        ...state,
        openedList: action.payload,
      };
    case GLOBAL_SEARCH:
      return {
        ...state,
        searchText: action.payload,
      };
    case TOGGLE_LOCK:
      return {
        ...state,
        isContentBlurred: !state.isContentBlurred,
      };

    case ACTIVE_USER:
      return {
        ...state,
        active_user: action.payload,
      };

    case FULL_USER:
      return {
        ...state,
        users: action.payload,
      };

    case ADD_LOGGED_USER:
      const newUser = action.payload;
      return {
        ...state,
        users: [...state.users, newUser],
      };
    case TOGGLE_OPPORTUNITY_LOCK:
      return {
        ...state,
        users: state.users.map((user) =>
          user.id === action.payload
            ? { ...user, blurred: !user.blurred }
            : user
        ),
      };
    case ADD_OPPORTUNITY_DETAILS:
      return {
        ...state,
        opportunityDetail: action.payload,
      };
    case ADD_OPPORTUNITIES:
      return {
        ...state,
        opportunities: action.payload,
      };
    case CREATE_GROUP:
      const newGroup = action.payload;
      return {
        ...state,
        groups: [...state.groups, newGroup],
      };

    default:
      return { ...state };
  }
};

export default Chat;
