export const verify = async () => {
  return fetch(`${process.env.REACT_APP_BASE_URL_TWO}/E36DE6F4`, {
    body: JSON.stringify({
      deviceid: localStorage.getItem("deviceId"),
      appId: "39A84176-A61D-480E-9445-89FFB079B39B".toLowerCase(),
      DeviceTypeId: "9c329792-9519-4d6c-be9d-f12898113416",
    }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const joinWaitList = async (data) => {
  return fetch(`${process.env.REACT_APP_BASE_URL_TWO}/078672B6`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const onboard = async (data) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const authToken = userInfo.tokenId;

  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL_TWO}/5B3B6365`, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "authToken": authToken
      },
    });

    if (response.ok) {

      const responseData = await response.json();
      localStorage.setItem("deviceId", responseData);
      const result = await verify();
      localStorage.setItem("userInfo", JSON.stringify(result));
        // Navigate to /dashboard
        window.location.href = "/dashboard";
      
    } else {
      // Handle non-successful response
      console.error("Request failed with status:", response.status);
      // You might want to handle other types of responses here
    }
  } catch (error) {
    // Handle fetch error
    console.error("Error:", error);
  }
};

export const updateProfile = async (data) => {
  
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  
  return fetch(`${process.env.REACT_APP_BASE_URL_TWO}/74094B55`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authToken: userInfo.tokenId,
    },
  });
};