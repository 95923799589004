import * as React from "react";
import favicon from "../assets/images/favicon.svg";
const imgStyle = {
  width: "50px", // Adjust the width as needed
  height: "auto", // Maintain aspect ratio
};
function Moukha(props) {
  return <img src={favicon} alt="Favicon" style={imgStyle} />;
}

const MemoMoukha = React.memo(Moukha);
export default MemoMoukha;
