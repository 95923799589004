import React, { useState, Suspense, useEffect } from "react";
import { PreLoader } from "../../components/PreLoader";
import "./style.css";
import NavbarPage from "./components/Navbar";
import Footer from "../Landing/components/Footer/Footer";
// Importing Section

const Contact = React.lazy(() => import("./components/Contact"));

const DesignPartner = () => {
  const [navItems, setNavItems] = useState([
    { id: 1, idnm: "home", navheading: "Home" },
    // { id: 7, idnm: "contact", navheading: "Join Us" },
  ]);
  const [pos, setPos] = useState(document.documentElement.scrollTop);
  const [navClass, setNavClass] = useState("");
  const [imglight, setImglight] = useState(false);
  useEffect(() => {
    async function fetchDataAndUpdateQuestions() {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL_ONE}/locations/countries?page_size=300`);
        const data = await response.json();
        const countriesData = data.data.map(item => ({
          id: item.id,
          name: item.name,
        }));
  
        // Sort countriesData array by name
        countriesData.sort((a, b) => a.name.localeCompare(b.name));
  
        const countryNamesWithQuotes = countriesData.map(item => `${item.name}`); // Adding quotes around each name
        console.log("Country Names:", countryNamesWithQuotes);
  
        localStorage.setItem("countries", JSON.stringify(countriesData));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    fetchDataAndUpdateQuestions();
  }, []);
  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        const scrollup = document.documentElement.scrollTop;
        if (scrollup > pos) {
          setNavClass("nav-sticky");
          setImglight(false);
        } else {
          setNavClass("");
          setImglight(false);
        }
        setPos(scrollup);
      },
      true
    );

    return () => window.removeEventListener("scroll", () => {});
  }, []);
  return (
    <React.Fragment>
      <Suspense fallback={<PreLoader />}>
        <NavbarPage
          navItems={navItems}
          navClass={navClass}
          imglight={imglight}
        />
        <Contact />
        <Footer />
      </Suspense>
    </React.Fragment>
  );
};

export default DesignPartner;
