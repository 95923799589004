import config from "../config";
export const track = async (data) => {
  try {
    const response = await fetch(config.API_URL_TRACK, {
      method: "POST",
      headers: {
        "Content-Type": "application/ejson",
        Accept: "application/json",
        apiKey: config.API_KEY,
      },
      body: JSON.stringify(data),
    });
    // Handle response if needed
  } catch (error) {
    // Handle fetch error
    console.error("Error:", error);
    throw error; // Re-throwing the error so it can be caught by the caller
  }
};
