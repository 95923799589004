import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { withRouter } from "react-router-dom";

import translationGr from './locales/gr/translation.json';
import translationIT from './locales/it/translation.json';
import translationSP from './locales/sp/translation.json';
import translationRS from './locales/rs/translation.json';
import translationENG from './locales/en/translation.json';

//translations
const resources = {
    gr: {
        translation: translationGr
    },
    it: {
        translation: translationIT
    },
    es: {
        translation: translationSP
    },
    rs: {
        translation: translationRS
    },
    en: {
        translation: translationENG
    }
};

// Check if language is stored in localStorage, otherwise use 'en'
const storedLanguage = localStorage.getItem("i18nextLng") || "en";

i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        resources,
        lng: storedLanguage,
        fallbackLng: "en",
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

// Redirect to "/dashboard" when language is changed
i18n.on('languageChanged', () => {
    // withRouter provides 'history' as a prop
    // eslint-disable-next-line no-restricted-globals
    const currentPath = window.location.pathname;
    if (currentPath.includes('/dashboard')) {
        window.location.href = '/dashboard';
    }
});

export default i18n;
