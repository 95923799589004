import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import classnames from "classnames";
import { connect, useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../helpers/authUtils";
import { setActiveTab, changeLayoutMode } from "../../redux/actions";

//Import Images
import logo from "../../assets/images/1PagePlus.png";
import avatar1 from "../../assets/images/users/default-avatar-img.webp";

//i18n
import i18n from "../../i18n";

// falgs
import usFlag from "../../assets/images/flags/us.jpg";
import spain from "../../assets/images/flags/spain.jpg";
import germany from "../../assets/images/flags/germany.jpg";
import italy from "../../assets/images/flags/italy.jpg";
import russia from "../../assets/images/flags/russia.jpg";
import { createSelector } from "reselect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faMessage } from "@fortawesome/free-solid-svg-icons";
import MemoMoukha from "../../components/Moukha";
import { track } from "../../services/track";
import {
  getAllIndustries,
  getNatureOfBusiness,
} from "../../services/opportunity";
import { useTranslation } from "react-i18next";
function LeftSidebarMenu(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [feedbackPopupOpen, setFeedbackPopupOpen] = useState(false);
  const nobData = [];
  useEffect(() => {
    onChangeLayoutMode();
    getNatureOfBusiness()
      .then(({ data }) => {
        localStorage.setItem("nobData", JSON.stringify(data));
        nobData = JSON.stringify(data);
      })
      .catch((err) => console.log("err", err));

    if (!nobData) {
      // nobData is empty, refresh the page
      window.location.reload();
    }
  }, []);
  const toggleFeedbackPopup = () => {
    setFeedbackPopupOpen(!feedbackPopupOpen);
  };
  const handleCoinsClick = () => {
    // Replace '/your-new-page' with the actual path you want to navigate to
    navigate("/credit");
  };
  const selectLayoutProperties = createSelector(
    (state) => state.Layout,
    (layout) => ({
      layoutMode: layout.layoutMode,
    })
  );
  const { layoutMode } = useSelector(selectLayoutProperties);

  const mode = layoutMode === "dark" ? "light" : "dark";

  const onChangeLayoutMode = (value) => {
    if (value) localStorage.setItem("persistedLayoutMode", value);
    const layout = localStorage.getItem("persistedLayoutMode");
    console.log("layoutMode", layout || value);
    dispatch(changeLayoutMode(layout || value));
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const [dropdownOpenMobile, setDropdownOpenMobile] = useState(false);
  const [lng, setlng] = useState("English");
  const metadata = {
    action: "LogoutButtonClicked",

    city: JSON.parse(localStorage.getItem("location"))?.city,
    country: JSON.parse(localStorage.getItem("location"))?.country,
    user_id: JSON.parse(localStorage.getItem("userInfo"))?.userID,
    ip: JSON.parse(localStorage.getItem("location"))?.query,
    // Add other metadata fields as needed
  };
  const currentDate = new Date().toISOString();
  const data = {
    dataSource: "metrics",
    database: "newopp_customer",
    collection: "events",
    document: {
      created_at: { $date: currentDate },
      metadata,
    },
  };
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const toggle2 = () => setDropdownOpen2(!dropdownOpen2);
  const toggleMobile = () => setDropdownOpenMobile(!dropdownOpenMobile);

  const toggleTab = (tab) => {
    props.setActiveTab(tab);
  };

  const activeTab = props.activeTab;

  /* changes language according to clicked language menu item */
  const changeLanguageAction = (lng) => {
    /* set the selected language to i18n */
    i18n.changeLanguage(lng);

    if (lng === "es") setlng("Spanish");
    else if (lng === "gr") setlng("German");
    else if (lng === "rs") setlng("Russian");
    else if (lng === "it") setlng("Italian");
    else if (lng === "en") setlng("English");
  };

  const handleLogout = () => {
    const nobData = localStorage.getItem("nobData");
    const industries = localStorage.getItem("industries");
    const testUser = localStorage.getItem("industries").isTestUser;
    if (!testUser) {
      track(data);
    }
    localStorage.clear();

    localStorage.setItem("industries", industries);
    localStorage.setItem("nobData", nobData);
    navigate("/login");
  };

  return (
    <React.Fragment>
      <div className="side-menu flex-lg-column me-lg-1">
        {/* LOGO */}
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <MemoMoukha style={{ height: "2.5rem", width: "2.5rem" }} />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <MemoMoukha style={{ height: "2.5rem", width: "2.5rem" }} />
            </span>
          </Link>
          <Nav
            className="side-menu-nav nav-pills justify-content-center"
            role="tablist"
          >
            <NavItem id="Chats">
              <NavLink
                id="pills-chat-tab"
                className={
                  classnames({ active: activeTab === "chat" }) + " mb-2"
                }
                onClick={() => {
                  toggleTab("chat");
                }}
              >
                <i className="ri-exchange-dollar-line"></i>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        {/* end navbar-brand-box  */}

        {/* Start side-menu nav */}
        <div className="flex-lg-column my-auto">
          <Nav
            className="side-menu-nav nav-pills justify-content-center"
            role="tablist"
          >
            {/* <NavItem id="profile">
                            <NavLink id="pills-user-tab" className={classnames({ active: activeTab === 'profile' }) + " mb-2"} onClick={() => { toggleTab('profile'); }}>
                                <i className="ri-user-2-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="profile" placement="top">
                            Profile
                        </UncontrolledTooltip> */}
            {/* <NavItem id="Chats">
              <NavLink
                id="pills-chat-tab"
                className={
                  classnames({ active: activeTab === "chat" }) + " mb-2"
                }
                onClick={() => {
                  toggleTab("chat");
                }}
              >
                <i className="ri-exchange-dollar-line"></i>
              </NavLink>
            </NavItem> */}
            <UncontrolledTooltip target="Chats" placement="top">
              {t("Opportunities")}
            </UncontrolledTooltip>
            {/* <NavItem id="Groups">
                            <NavLink id="pills-groups-tab" className={classnames({ active: activeTab === 'group' }) + " mb-2"} onClick={() => { toggleTab('group'); }}>
                                <i className="ri-group-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Groups" placement="top">
                            Groups
                        </UncontrolledTooltip> */}
            {/* <NavItem id="Contacts">
                            <NavLink id="pills-contacts-tab" className={classnames({ active: activeTab === 'contacts' }) + " mb-2"} onClick={() => { toggleTab('contacts'); }}>
                                <i className="ri-contacts-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Contacts" placement="top">
                            Contacts
                        </UncontrolledTooltip> */}
            {/* <NavItem id="Settings">
                            <NavLink id="pills-setting-tab" className={classnames({ active: activeTab === 'settings' })} onClick={() => { toggleTab('settings'); }}>
                                <i className="ri-settings-2-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Settings" placement="top">
                            Settings
                        </UncontrolledTooltip> */}
            <Dropdown
              nav
              isOpen={dropdownOpenMobile}
              toggle={toggleMobile}
              className="profile-user-dropdown d-inline-block d-lg-none dropup"
            >
              <DropdownToggle nav>
                <img
                  src={avatar1}
                  alt="chatvia"
                  className="profile-user rounded-circle"
                />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => {
                    toggleTab("profile");
                  }}
                >
                  {t("Profile")}{" "}
                  <i className="ri-profile-line float-end text-muted"></i>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    toggleTab("settings");
                  }}
                >
                  {t("Setting")}{" "}
                  <i className="ri-settings-3-line float-end text-muted"></i>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={handleLogout}>
                  {t("Log out")}{" "}
                  <i className="ri-logout-circle-r-line float-end text-muted"></i>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </div>
        {/* end side-menu nav */}

        <div className="flex-lg-column d-none d-lg-block">
          <Nav className="side-menu-nav justify-content-center">
            <Dropdown
              nav
              isOpen={dropdownOpen2}
              className="btn-group dropup profile-user-dropdown"
              toggle={toggle2}
            >
              <DropdownToggle nav>
                <i className="ri-global-line"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => changeLanguageAction("en")}
                  active={lng === "English"}
                >
                  <img src={usFlag} alt="user" className="me-1" height="12" />{" "}
                  <span className="align-middle">English</span>
                </DropdownItem>

                <DropdownItem
                  onClick={() => changeLanguageAction("es")}
                  active={lng === "Spanish"}
                >
                  <img src={spain} alt="user" className="me-1" height="12" />{" "}
                  <span className="align-middle">Spanish</span>
                </DropdownItem>
                {/* 
                <DropdownItem
                  onClick={() => changeLanguageAction("gr")}
                  active={lng === "German"}
                >
                  <img src={germany} alt="user" className="me-1" height="12" />{" "}
                  <span className="align-middle">German</span>
                </DropdownItem>

                <DropdownItem
                  onClick={() => changeLanguageAction("it")}
                  active={lng === "Italian"}
                >
                  <img src={italy} alt="user" className="me-1" height="12" />{" "}
                  <span className="align-middle">Italian</span>
                </DropdownItem>

                <DropdownItem
                  onClick={() => changeLanguageAction("rs")}
                  active={lng === "Russian"}
                >
                  <img src={russia} alt="user" className="me-1" height="12" />{" "}
                  <span className="align-middle">Russian</span>
                </DropdownItem> */}
              </DropdownMenu>
            </Dropdown>

            <li className="nav-item">
              <NavLink
                id="light-dark"
                className="mb-2"
                onClick={() => onChangeLayoutMode(mode)}
              >
                <i className="ri-sun-line theme-mode-icon"></i>
              </NavLink>
              <UncontrolledTooltip target="light-dark" placement="right">
                {t(" Dark / Light Mode")}
              </UncontrolledTooltip>
            </li>

            <li className="nav-item">
              <NavLink
                id="coins"
                className="mb-2"
                onClick={handleCoinsClick}
                style={{ position: "relative" }}
              >
                <FontAwesomeIcon icon={faCoins} style={{}} />
                <span
                  style={{
                    position: "absolute",
                    top: "70%",
                    left: "70%",
                    transform: "translate(-50%, -50%)",
                    minWidth: "1.5rem",
                    height: "1.5rem",
                    borderRadius: "50%",
                    background: "grey",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0.25rem",
                    fontSize: "0.75rem",
                    color: "white",
                  }}
                >
                  {getUserInfo().credits || "0"}
                </span>
              </NavLink>

              <UncontrolledTooltip target="coins" placement="right">
                {t("creditsTooltip", { count: getUserInfo().credits || 0 })}
              </UncontrolledTooltip>
            </li>
            <Dropdown
              nav
              isOpen={dropdownOpen}
              className="nav-item btn-group dropup profile-user-dropdown"
              toggle={toggle}
            >
              <DropdownToggle className="nav-link mb-2" tag="a">
                <img
                  src={avatar1}
                  alt=""
                  className="profile-user rounded-circle"
                />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    toggleTab("profile");
                  }}
                >
                  {t("Profile")}{" "}
                  <i className="ri-profile-line float-end text-muted"></i>
                </DropdownItem>
                {/* <DropdownItem
                  onClick={() => {
                    toggleTab("settings");
                  }}
                >
                  Setting{" "}
                  <i className="ri-settings-3-line float-end text-muted"></i>
                </DropdownItem> */}
                <DropdownItem divider />
                <DropdownItem onClick={handleLogout}>
                  {t("Log out")}{" "}
                  <i className="ri-logout-circle-r-line float-end text-muted"></i>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </div>
        {/* Side menu user */}
      </div>
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
    openedList: state.Chat.openedList,
  };
};

export default connect(mapStatetoProps, {
  setActiveTab,
})(LeftSidebarMenu);
