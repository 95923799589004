import axios from 'axios';
import config from "./../config";


// default
axios.defaults.baseURL = config.API_URL;

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';

// intercepting to capture errors
axios.interceptors.response.use(function (response) {
    return response.data ? response.data : response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
        case 500: message = 'Internal Server Error'; break;
        case 401: message = 'Invalid credentials'; break;
        case 404: message = "Sorry! the data you are looking for could not be found"; break;
        default: message = error.message || error;
    }
    return Promise.reject(message);
});

/**
 * Sets the default authorization
 * @param {*} token 
 */
const setAuthorization = (token) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}


class APIClient {
    /**
     * Fetches data from given url
     */
    get = (url, params) => {
        return axios.get(url, params);
    }

    /**
     * post given data to url
     */
    create = (url, data) => {
        return axios.post(url, data);
    }

    /**
     * Updates data
     */
    update = (url, data) => {
        return axios.patch(url, data);
    }

    /**
     * Delete 
     */
    delete = (url) => {
        return axios.put(url);
    }
}

 const newGuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      let r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const getDataForVerifyModel = (deviceId) => {
    let query = "";
    let countryCode = "";
    return {
      IPAdress: query,
      BrowserData: "", /// TODO: send the browser data
      DeviceId: deviceId,
      DeviceTypeId: "9c329792-9519-4d6c-be9d-f12898113416",
      OTP: deviceId,
      CountryId: countryCode,
      PhoneNumber: "",
      EmailAddress: "",
      Coupon: "",
      LanguageId: "English (US)",
      IsPrimaryDevice: false,
      AppId: "39A84176-A61D-480E-9445-89FFB079B39B",
      DeviceData: "",
    };
  };




export { APIClient, setAuthorization,newGuid,getDataForVerifyModel };