import React, { useEffect } from "react";
import withRouter from "../../components/withRouter";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { changeLayoutMode } from "../../redux/actions";
import LeftSidebarMenu from "./LeftSidebarMenu";

const Index = ({ children }) => {
  return (
    <React.Fragment>
      <div className="layout-wrapper d-lg-flex">
        <LeftSidebarMenu />
        {/* render page content */}
        {children}
      </div>
    </React.Fragment>
  );
};

Index.propTypes = {
  layoutMode: PropTypes.any,
};

const mapStateToProps = (state) => ({
  layoutMode: state.Layout.layoutMode,
});

export default withRouter(
  connect(mapStateToProps, { changeLayoutMode })(Index)
);
