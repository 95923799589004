export const CHAT_USER = "CHAT_USER";
export const ACTIVE_USER = "ACTIVE_USER";
export const FULL_USER = "FULL_USER";
export const ADD_LOGGED_USER = "ADD_LOGGED_USER";
export const CREATE_GROUP = "CREATE_GROUP";
export const TOGGLE_LOCK = "TOGGLE_LOCK";
export const TOGGLE_OPPORTUNITY_LOCK = "TOGGLE_OPPORTUNITY_LOCK";
export const ADD_OPPORTUNITY_DETAILS = "ADD_OPPORTUNITY_DETAILS";
export const ADD_OPPORTUNITIES = "ADD_OPPORTUNITIES";
export const UPDATE_REVEAL = "UPDATE_REVEAL";
export const OPENED_REVEAL = "OPENED_REVEAL";
export const GLOBAL_SEARCH = "GLOBAL_SEARCH";
export const TWAK_API= "https://embed.tawk.to/66014373a0c6737bd1245875/1hpqeuv2a";
