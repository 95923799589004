import React, { useEffect } from "react";
import { Button, Spinner } from "reactstrap";
import { verify } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import withRouter from "../../components/withRouter";
import { connect } from "react-redux";
import { loginUser, apiError } from "../../redux/actions";
import { useTranslation } from "react-i18next";
import useLoader from "../../hooks/useLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { toastDetails } from "../../constant";
import Logo from "../../assets/images/moukha-logo.png";
import { track } from "../../services/track";
const verifyStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "70vh",
  flexDirection: "column",
  gap: "1rem",
};
const headerStyle = {
  textAlign: "right",
  padding: "1rem 1.5rem",
};
const Verify = (props) => {
  const { loader, setLoader } = useLoader();
  const navigate = useNavigate();
  const metadata = {
    action: "VerifyPageVisited",
    user_id: JSON.parse(localStorage.getItem("userInfo"))?.userID,
    city: JSON.parse(localStorage.getItem("location"))?.city,
    country: JSON.parse(localStorage.getItem("location"))?.country,
    ip: JSON.parse(localStorage.getItem("location"))?.query,
  };
  const currentDate = new Date().toISOString();
  const data = {
    dataSource: "metrics",
    database: "newopp_customer",
    collection: "events",
    document: {
      created_at: { $date: currentDate },
      metadata,
    },
  };
  const { t } = useTranslation();
  const handleVerify = async () => {
    setLoader(true);
    try {
      const result = await verify();
      console.log("result?.isExistingCustomer", result?.isExistingCustomer);
      if (!result?.isExistingCustomer) {
        localStorage.setItem("userInfo", JSON.stringify(result));
        navigate("/onboarding");
      } else {
        localStorage.setItem("userInfo", JSON.stringify(result));
        navigate("/dashboard");
      }
    } catch (error) {
      console.log("verify failed", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    track(data);
  }, []);

  return (
    <>
      <div style={headerStyle}>
        <FontAwesomeIcon
          icon={faXmark}
          fontSize={"1.5rem"}
          onClick={() => navigate(-1)}
          style={{
            cursor: "pointer",
          }}
        />
      </div>
      <div style={verifyStyle}>
        <img
          src={Logo}
          alt="NEWOPP-logo"
          style={{
            height: "9rem",
            width: "9rem",
            objectFit: "cover",
          }}
        />
        <Button
          color="primary"
          onClick={handleVerify}
          disabled={loader}
          style={{
            padding: "0.75rem 1.5rem",
            width: "10rem",
          }}
        >
          {loader ? (
            <>
              <Spinner size="sm" />
              <span> {t("Verifying")}...</span>
            </>
          ) : (
            t("Verify")
          )}
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};
export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Verify)
);
