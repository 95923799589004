import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import FooterLink from "../Footer/Footer_link";

// Footer Image
import footerImage from "../../../../assets/images/hero-1-bg-img.png";

// Import Logo
import logoLight from "../../../../assets/images/logo-light.png";

// Import icon
import FeatherIcon from "feather-icons-react";
import MemoMoukha from "../../../../components/Moukha";

const Footer = () => {
  const linksData = [
    {
      id: 1,
      title: "Customer",
      child: [
        { title: "Works", link: "/" },
        { title: "Strategy", link: "/" },
        { title: "Releases", link: "/" },
        { title: "Press", link: "/" },
        { title: "Mission", link: "/" },
      ],
    },
    {
      id: 2,
      title: "Product",
      child: [
        { title: "Tranding", link: "/" },
        { title: "Popular", link: "/" },
        { title: "Customers", link: "/" },
        { title: "Features", link: "/" },
      ],
    },
    {
      id: 3,
      title: "Learn More",
      child: [
        { title: "Developers", link: "/" },
        { title: "Support", link: "/" },
        { title: "Customer Service", link: "/" },
        { title: "Get Started", link: "/" },
        { title: "Guide", link: "/" },
      ],
    },
  ];

  return (
    <React.Fragment>
      {/* Footer Start */}
      <footer
        className="footer"
        id="footer"
        style={{ backgroundImage: `url(${footerImage})` }}
      >
        <Container>
          <Row>
            <Col lg={4}>
              <div className="mb-5">
                <Link
                  to="#"
                  className="d-flex mb-4  align-items-center"
                  style={{ gap: "1rem" }}
                >
                  <MemoMoukha
                    style={{
                      width: "3rem",
                      height: "3rem",
                    }}
                  />
                  <h3 className="font-weight-normal">NEWOPP</h3>
                </Link>
                <p className="text-white-50 my-4">
                NEWOPP SOLUTIONS PRIVATE LIMITED
No 363, 19th Main, Rajajinagar 1st Block, Rajajinagar, Bengaluru, KA, IN, 560010
                </p>
                {/* <Link to="#" className="text-white-70">
                  <FeatherIcon className="icon mr-1" icon="linkedin" />{" "}
                  <span className="ml-1">Join Us In LinkedIn</span>
                </Link> */}
              </div>
            </Col>
          </Row>
          {/* Render Footer Link End */}
          <FooterLink />
        </Container>
      </footer>
      {/* Footer End */}
    </React.Fragment>
  );
};

export default Footer;
