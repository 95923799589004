import React, { Suspense } from "react";
import { Routes as SwitchRoute, Route, Navigate } from "react-router-dom";

//import routes
import { authProtectedRoutes, publicRoutes } from "./routes";

//import layouts
import NonAuthLayout from "../layouts/NonAuth";
import AuthLayout from "../layouts/AuthLayout/";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isUserAuthenticated } from "../helpers/authUtils";
const AuthProtected = (props) => {
  // Log the current URL before navigating
  console.log("Current URL before navigating:", window.location.pathname);

  const allowedPaths = ["/", "/design-partner", "/invite/:id", "/verify", "/login","/dashboard"];

  if (props.isAuthProtected && !localStorage.getItem("userInfo")) {
    // Check if the current URL is not in the allowed paths
    if (!allowedPaths.includes(window.location.pathname)) {
      return <Navigate to="*" />; // Redirect to your 404 page or catch-all route
    }

    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};


const WhenAuthorized = ({ children }) => {
  const path = window.location.pathname;
  if (isUserAuthenticated() && path === "/login") {
    return <Navigate to="/dashboard" replace />;
  }

  return <>{children}</>;
};
/**
 * Main Route component
 */
const Routes = () => {
  return (
    // rendering the router with layout
    <React.Fragment>
      <ToastContainer />
      <Suspense fallback={<div></div>}>
        <SwitchRoute>
          {/* public routes */}
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<WhenAuthorized>{route.component} </WhenAuthorized>}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {/* private/auth protected routes */}
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <AuthProtected isAuthProtected={true}>
                  <AuthLayout>{route.component}</AuthLayout>
                </AuthProtected>
              }
              key={idx}
              isAuthProtected={true}
            />
          ))}
        </SwitchRoute>
      </Suspense>
    </React.Fragment>
  );
};

export default Routes;
